<template>
  <div v-if="!isActivated">
    <!-- Active Trial: Uses Vue I18n pluralization -->
    <div v-if="days !== null && +days > 0" class="trial-counter-bar-blue">
      <p class="my-0 txt" v-if="days == 1">
     {{ $t("dashboard.freeTrialExpiresInone", { day: convertDaysToWords() }) }}
    </p>
    <p class="my-0 txt" v-else>
 
     {{ $t("dashboard.freeTrialExpiresInoneother", { day: convertDaysToWords() }) }}
    </p>
      <router-link
        to="/billingAndSubscription"
        class="btn yellow-button"
        :class="{ 'ar-spacing-btn': isArabicLocale }"
      >
        {{ $t("dashboard.subscribeNow") }}
      </router-link>
    </div>

    <!-- Expired Trial -->
    <div v-else-if="+days === 0" class="trial-counter-bar-red">
      <p class="my-0 txt">{{ $t("dashboard.freeTrialExpired") }}</p>
      <router-link
        to="/billingAndSubscription"
        class="btn red-button"
        :class="{ 'ar-spacing-btn': isArabicLocale }"
      >
        {{ $t("dashboard.subscribe") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import writtenNumber from "written-number";
import ls from "localstorage-slim";
import arabicLocale from "@/mixins/arabicLocale.js";

ls.config.encrypt = true;

export default {
  name: "TrialCounterBar",
  mixins: [arabicLocale],
  data() {
    return {
      days: localStorage.getItem("days"),
      isActivated: false,
      subscriptionStatus: ls.get("SubscriptionStatus")
    };
  },
 
  methods: {
   
    convertDaysToWords() {
      const currentLang = this.isArabicLocale ? "ar" : "en";
      writtenNumber.defaults.lang = currentLang;
      return writtenNumber(this.days);
    }
  },
  watch: {
    subscriptionStatus: {
      handler() {
        const isActiveStatus = this.subscriptionStatus.toLowerCase() === "active" || this.subscriptionStatus.toLowerCase().includes("active");
        this.isActivated = isActiveStatus;
        console.log(this.subscriptionStatus , "subscriptionStatus" , isActiveStatus)
      },
      deep: true
    }
  },
  mounted() {
    const isActiveStatus = this.subscriptionStatus ? this.subscriptionStatus.toLowerCase() === "active" || this.subscriptionStatus.toLowerCase().includes("active") : false;
        this.isActivated = isActiveStatus;
    this.days = localStorage.getItem("days");
  }
}
</script>

<style scoped>
.trial-counter-bar-blue {
  width: 100%;
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.trial-counter-bar-red {
  width: 100%;
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.txt {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #002873;
}

.yellow-button {
  background-color: var(--yellow);
  color: #fff;
  border-radius: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  border: none;
  border: 2px solid var(--yellow);
  width: 142px;
  height: 34px;
  margin-left: 24px;
}

.yellow-button:hover {
  background-color: #fff;
  color: var(--yellow);
  border: 2px solid var(--yellow);
}

.red-button {
  background-color: var(--red);
  color: #fff;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  border: none;
  border: 2px solid var(--red);
  width: 142px;
  height: 34px;
  margin-left: 24px;
}

.red-button:hover {
  background-color: #fff;
  color: var(--red);
  border: 2px solid var(--red);
}

.ar-spacing-btn {
  margin-left: 0;
  margin-right: 24px;
}

@media screen and (max-width: 767px) {
  .trial-counter-bar-blue,
  .trial-counter-bar-red {
    flex-direction: column;
    text-align: center;
    padding: 0 10px;
    row-gap: 10px;
  }
  .yellow-button {
    margin-left: 0;
  }
}
</style>
