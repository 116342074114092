// products/router.js
import store from "../users/store";

const moduleRoute = {
  path: "/products",
  component: () =>
    import(
      /* webpackChunkName: "products-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "", // ← معناها: /products
      component: () =>
        import(
          /* webpackChunkName: "products-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },
    {
      path: ":id", // ← معناها: /products/123
      component: () =>
        import(
          /* webpackChunkName: "products-view", webpackPrefetch: true */ "./views/ProductView.vue"
        ),
    },
    {
      path: "import", // ← معناها: /products/import
      name: "ImportProducts",
      component: () =>
        import(
          /* webpackChunkName: "products-import", webpackPrefetch: true */ "./views/ImportProducts.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
    } else {
      localStorage.setItem("layout", "landing-layout");
      next("/login");
    }
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
