import { warningToast, errorToast } from "@/helpers/toastifications";
import { deleteCookie } from "@/helpers/store-cookies";

export function errorHandler(error) {
  if (error.response) {
    switch (error?.response?.status) {
      case 401:
        deleteCookie("access_token");
        localStorage.removeItem("FullName");
        errorToast("Email or Password not correct!");
        window.location.replace("/");
        break;

      case 402:
        warningToast(error.response.data.message);
        break;

      case 400:
        errorToast(error.response.data.message);
        break; 
        
      case 409:
        errorToast(error.response.data.message);
        break;   

      case 403:
        window.location.replace("/notAuthorizedForProject");
        break;

      case 404:
        warningToast("Not found!");
        break;

      case 500:
        errorToast("Something went wrong!");
        break;

      case 450:
        window.location.replace("/login");
        break;

      case 200:
        window.location.replace("/dashboard");
        break;

      default:
        errorToast("An unknown error occurred!");
        break;
    }
  } else {
    errorToast("An error occurred but no response was received!");
    console.error("Error object:", error);
  }
}
