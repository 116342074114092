<template>
  <div id="app" :class="{ 'dir-rtl': isRtl }">
    <div
      v-if="loading == true"
      class="flex content-center absolute loading-height align-items-center w-100"
    >
      <LoadingComp />
    </div>
    <component v-if="loading == false" :is="layout"></component>

    <success-toast></success-toast>
    <warning-toast></warning-toast>
    <error-toast></error-toast>

    <!-- <div id="mouse-follower">
      <img height="48" width="48" src="./assets/santa.png" alt="">
    </div> -->
  </div>
</template>

<script>
import LandingLayout from "./layouts/LandingLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import SuccessToast from "@/components/toasts/SuccessToast.vue";
import WarningToast from "@/components/toasts/WarningToast.vue";
import ErrorToast from "@/components/toasts/ErrorToast.vue";
import LoadingComp from "../src/components/loading/LoadingComp.vue";

export default {
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    '$i18n.locale'() {
      // When language changes, redirect to /products only if we're on /products/import
      if (window.location.pathname.includes('/products/import')) {
        this.$router.replace('/products').catch(() => {});
      }
    }
  },
  computed: {
    layout() {
      return localStorage.getItem("layout");
    },
    isRtl() {
      this.loading = false;
      return this.$i18n.locale === "ar";
    },
  },

  components: {
    "landing-layout": LandingLayout,
    "dashboard-layout": DashboardLayout,
    SuccessToast,
    WarningToast,
    ErrorToast,
    LoadingComp,
  },

  mounted() {
    // document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));
    // let mouseFollower = document.getElementById('mouse-follower');
    // const onMouseMove = (e) =>{
    //   mouseFollower.style.left = (e.pageX+50) + 'px';
    //   mouseFollower.style.top = (e.pageY+50) + 'px';
    // }
    // document.addEventListener('mousemove', onMouseMove);
  },
  created() {
    const locale = localStorage.getItem("locale");
    locale && (this.$i18n.locale = locale);
    
    // On page reload, redirect to /products only if we're on /products/import
    if (window.location.pathname.includes('/products/import')) {
      this.$router.replace('/products').catch(() => {});
    }
    this.loading = false;
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Avenir:wght@300;500&display=swap"); */
@import url("./assets/styles/buttons.css");
@import url("./assets/styles/navs.css");
@import url("./assets/styles/tables.css");
@import url("./assets/styles/statuses.css");

@font-face {
  font-family: "Avenir";
  src: url("assets/fonts/Avenir\ Black.ttf");
}

@font-face {
  font-family: "Avenir";
  src: url("assets/fonts/Avenir\ Regular.ttf");
}

@font-face {
  font-family: "GE SS";
  src: url("assets/fonts/alfont_com_AlFont_com_GE_SS_Two_Medium-1.otf");
}

@font-face {
  font-family: "GE SS";
  src: url("assets/fonts/alfont_com_AlFont_com_GE_SS_Two_Medium-1.otf");
}

#app {
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

html {
  scroll-behavior: smooth;
}

.vs__dropdown-toggle {
  border: 2px solid var(--label);
}

.vs__actions {
  display: none;
}

.vs__open-indicator,
.vs__clear {
  display: none;
}

.home-placeholder {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-placeholder img {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .home-placeholder {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .home-placeholder img {
    width: 100%;
  }
}

.add-new-list-btn {
  cursor: pointer;
  margin: 10px 0px 10px 5px;
}

#mouse-follower {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  /* transition: all 0.2s; */
  z-index: 200;
}

.font-12 {
  font-size: 12px;
}

/* and (-webkit-device-pixel-ratio: 1) (-webkit-max-device-pixel-ratio: 3) and */

/* @media  (-webkit-min-device-pixel-ratio: 1.25) {
  :root {
    zoom: 0.8;
  }

  .g-recaptcha {
    zoom: 1.4 !important;
  }
}

@media  (-webkit-max-device-pixel-ratio: 1.25) {
  :root {
    zoom: 0.8;
  }

  .g-recaptcha {
    zoom: 1.4 !important;
  }
}

@media  screen and (max-width: 1366px) {
  .v-select, .v-select * {
    max-width: 100%;
  }

  .g-recaptcha {
    zoom: 1.4 !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  :root {
    zoom: 1;
  }

  .v-select, .v-select * {
    max-width: 99%;
  }

  .g-recaptcha {
    zoom: 1.4 !important;
  }
}  */
.dir-rtl {
  direction: rtl !important;
  font-family: "GE SS" !important;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.color-main {
  color: #00205c;
}

.bg-color-main {
  background-color: #00205c;
}

.rounded-xl {
  border-radius: 13px !important;
}

.rounded-md {
  border-radius: 9px !important;
}

.border-main {
  border: 1px solid #00709c;
}

.main-new-color {
  color: #00709c;
}

.bg-main-new {
  background-color: #00709c !important;
}

.text-sm {
  font-size: 12px;
}
.swal2-container {
  z-index: 99999999999 !important;
}
</style>
