import axios from "axios";
import Vue from "vue";
import { successToast } from "@/helpers/toastifications";
import { AXIOS } from "../../../axios";

export default {
  namespaced: true,
  state: {
    accountTypes: [],
    detailsTypes: [],
    chartsofAccountsList: [],
    chartsofAccountsTable: [],
    coaById: {},
    currencies: [],
    coaID: "",
    displayID: false,
    loading: true,
    viewloading: true,
    nameExistMsg: "",
    paymentAccounts: [],
    coaForExpenses: [],
    coaForJournalEntry: [],
    showTable: false,
    addingCoa: false,
    viewAccount: {},
    autoSelectedAccounts: {},
    openJournalEntry: false,
    openEditJournalEntry: false,
  },
  getters: {
    accountTypes: (state) => state.accountTypes,
    openJournalEntry: (state) => state.openJournalEntry,
    openEditJournalEntry: (state) => state.openEditJournalEntry,

    viewAccount: (state) => state.viewAccount,

    currencies: (state) => state.currencies,

    detailsTypes: (state) => state.detailsTypes,

    chartsofAccountsList: (state) => state.chartsofAccountsList,

    chartsofAccountsTable: (state) => state.chartsofAccountsTable,

    coaById: (state) => state.coaById,

    coaID: (state) => state.coaID,

    displayID: (state) => state.displayID,

    loading: (state) => state.loading,

    viewloading: (state) => state.viewloading,

    nameExistMsg: (state) => state.nameExistMsg,

    paymentAccounts: (state) => state.paymentAccounts,

    coaForExpenses: (state) => state.coaForExpenses,

    coaForJournalEntry: (state) => state.coaForJournalEntry,

    showTable: (state) => state.showTable,

    addingCoa: (state) => state.addingCoa,

    autoSelectedAccounts: (state) => state.autoSelectedAccounts,
  },
  mutations: {
    setAccountTypes: (state, data) => {
      state.accountTypes = data;
    },
    setViewAccount: (state, data) => {
      state.viewAccount = data;
    },

    setDetailsTypes: (state, data) => {
      state.detailsTypes = data;
    },

    setChartsofAccountsList: (state, data) => {
      state.chartsofAccountsList = data;
    },
    setOpenJournalEntry: (state, data) => {
      state.openJournalEntry = data
    },
    setOpenEditJournalEntry: (state, data) => {
      state.openEditJournalEntry = data
    },
    setChartsofAccountsTable: (state, data) => {
      state.chartsofAccountsTable = data;
    },

    setCoaById: (state, data) => {
      state.coaById = data;
    },

    setCoaID: (state, data) => {
      state.coaID = data;
    },

    setDisplayID: (state, data) => {
      state.displayID = data;
    },

    set_loading: (state, data) => {
      state.loading = data;
    },

    set_viewloading: (state, data) => {
      state.viewloading = data;
    },

    setNameExistMsg: (state, data) => {
      state.nameExistMsg = data;
    },

    setPaymentAccount: (state, data) => {
      state.paymentAccounts = data;
    },

    set_coaForExpenses: (state, data) => {
      state.coaForExpenses = data;
    },

    set_coaForJournalEntry: (state, data) => {
      state.coaForJournalEntry = data;
    },

    set_showTable: (state, data) => {
      state.showTable = data;
    },

    set_addingCoa: (state, data) => {
      state.addingCoa = data;
    },

    setCurrencies: (state, data) => {
      state.currencies = data;
    },
    set_auto_selected_accounts: (state, data) => {
      state.autoSelectedAccounts = data;
    },
  },
  actions: {
    openJournalEntryAction: ({ commit }, data) => {
      commit("setOpenJournalEntry", data);
    },
    openEditJournalEntryAction: ({ commit }, data) => {
      commit("setOpenEditJournalEntry", data);
    },
    openAddAccountPopup() {
      const addAccountModal = document.getElementById("addAccountModal");
      addAccountModal.style.display = "flex";
    },

    closeAddAccountPopup() {
      const addAccountModal = document.getElementById("addAccountModal");
      addAccountModal.style.display = "none";
    },

    openEditAccountPopup() {
      const addAccountModal = document.getElementById("editAccountModal");
      addAccountModal.style.display = "flex";
    },

    closeEditAccount() {
      const addAccountModal = document.getElementById("editAccountModal");
       addAccountModal.style.display = "none";
    },

    accountTypeAction: async ({ commit }) => {
      try {
        const res = await AXIOS.get(`COA/GetMainChart`);
        commit("setAccountTypes", res.data.MainChart);

      } catch (error) {
        console.log(error.message);
        error.message;
      }
    },

    detailsTypesAction: async ({ commit }, id) => {
      try {
        const res = await AXIOS.post(`COA/GetAllDetails/${id}`, {});
        commit("setDetailsTypes", res.data.MainChartDetailed);
      } catch (error) {
        console.log(error.message);
        error.message;
      }
    },

    // allChartsofAccountsAction: async ({commit}) => {
    //     try {
    //         const res = await AXIOS.get(`COA/GetMainChart`)
    //         commit('setChartsofAccountsList', res.data.MainChart)
    //     } catch (error) {
    //         (error.message);
    //     }
    // },

    emptyChartsofAccountsAction: ({ commit }) => {
      commit("setAccountTypes", []);
    },
    chartsofAccountsTableAction: ({ commit }, search) => {
      let route;
      if (search !== undefined) {
        route = `COA/GetCOA?search=${search}`;
      } else {
        route = `COA/GetCOA`;
      }

      AXIOS.get(route, {
        search: search,
        page: localStorage.getItem("page"),
      })
        .then((res) => {
          // (res.data);
          commit("setChartsofAccountsTable", res.data.COAList);
          commit("set_showTable", res.data.showTable);

          // alert('success')
        })
        .catch((err) => {
          err.message;
          // Rollbar.error("Something went wrong", err);
        })
        .finally(() => {
          commit("set_loading", false);
        });
    },

    addCOAAction: ({ commit, dispatch, state }, payload) => {
      return new Promise((resolve, reject) => {
        commit("set_addingCoa", true);
        AXIOS.post(`COA/AddChartsOfAccounts`, payload)
          .then((response) => {
            console.log("Success Response:", response);
            
            // Check if we have a response
            if (!response) {
              throw new Error("No response received from server");
            }

            // Check if we have response data
            if (!response.data) {
              throw new Error("No data received from server");
            }

            // Check if we have an error message in the response
            if (response.data.status === "error") {
              throw new Error(response.data.message || "Failed to add chart of accounts");
            }

            // Success case
            commit("set_auto_selected_accounts", response.data);
            dispatch("get_coaForJournalEntry_action");
            resolve(true);
          })
          .catch((error) => {
            console.error("Error adding COA:", error);
            console.error("Error details:", {
              status: error?.response?.status,
              data: error?.response?.data,
              message: error?.message
            });
            
            // Get appropriate error message
            let errorMessage = "Failed to add chart of accounts";
            if (error?.response?.data?.message) {
              errorMessage = error.response.data.message;
            } else if (error.message) {
              errorMessage = error.message;
            }
            
            reject(new Error(errorMessage));
          })
          .finally(() => {
            commit("set_addingCoa", false);
          });
      });
    },

    coaByIdAction: ({ commit }, id) => {
      AXIOS.get(`COA/GetCOAByID/${id}`)
        .then((res) => {
          commit("setCoaById", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_viewloading", false);
        });
    },

    setCoaIDAction: ({ commit }, data) => {
      commit("setCoaID", data);
    },

    setDisplayIDAction: ({ commit }, data) => {
      commit("setDisplayID", data);
    },

    checkNameExistAction: ({ commit }, name) => {
      AXIOS.get(`COA/COAExist/${name}`)
        .then((res) => {
          // (res);
          if (res.status === 200) {
            commit("setNameExistMsg", "");
          }
        })
        .catch((err) => {
          err.message;
          // (err.response.status);
          // if(err.response.status === 409)  {
          //     ('409');
          // }
        });
    },

    setNameExistMsgAction: ({ commit }, data) => {
      commit("setNameExistMsg", data);
      // ('from action', data);
    },

    getPaymentAccountsAction: ({ commit }) => {
      AXIOS.get("Forms/GetAllExpenseCOA")
        .then((res) => {
          if (res && res.data && res.data.COAList) {
            commit("setPaymentAccount", res.data.COAList);
          }
        })
        .catch((err) => {
          console.error("Error fetching payment accounts:", err);
        });
    },

    get_coaForExpenses_action: ({ commit }) => {
      AXIOS.post("Forms/GetAllExpencesCOA", {})
        .then((res) => {
          if (res && res.data && res.data.COAList) {
            commit("set_coaForExpenses", res.data.COAList);
          }
        })
        .catch((err) => {
          console.error("Error fetching COA for expenses:", err);
        });
    },

    get_coaForJournalEntry_action: ({ commit }, search) => {
      AXIOS.get("COA/GetCOA")
        .then((res) => {
          console.log(res.data.COAList)
          commit("set_coaForJournalEntry", res.data.COAList);
        })
        .catch((err) => {
          console.log(err)

          err.message;
        });
    },

    editAccountAction: ({ commit, dispatch }, payload) => {
      // dispatch('set_refreshCustomerList_action', false);
      const addAccountModal =  document.querySelector("#editAccountModal");

      return AXIOS.post(`COA/EditChartsOfAccount/${payload.chart_id}`, {
        account_name: payload.name_en,
        account_name_ar: payload.name_ar,
        user_description: payload.description,
        main_charts_id: payload.chart_id,
        account_currency: payload.currency,
        serial: payload.serial,
        opening_unpaid_balance: payload.unpaidBalance,
        opening_unpaid_balance_date: payload.unpaidBalanceDate,
        opening_balance: payload.balance,
        opening_balance_date: payload.balanceDate,
        opening_original_cost: payload.originalCost,
        opening_original_cost_date: payload.originalCostDate,
        opening_depreciation: payload.depreciation,
        opening_depreciation_date: payload.depreciationDate,
        chart_account_details_id: payload.detailTypeId,
      })
        .then((res) => {
          if (res?.status === 200 || res?.data?.Message === 'Done') {
 
             if(addAccountModal){
              addAccountModal.style.display = "none";
            }
      
             dispatch("chartsofAccountsTableAction");
             window.location.reload();
            
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    getCurrenciesAction: ({ commit }) => {
      AXIOS.get(`Forms/GetAllCurrency`)
        .then((res) => {
          commit("setCurrencies", res.data);

          // (res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    get_COAById_action: ({ commit }, id) => {
       AXIOS.get(`COA/GetCOAForEdit/${id}`)
        .then((res) => {
          console.log(res.data)
          commit("setViewAccount", res.data.COAList);
        })
        .catch((err) => {
          err.message;
        });
    },
    set_autoSelectedAccount_action: ({ commit, state, dispatch }) => {
      "state ", state.chartsofAccountsTable.length;
      "log the table",
        state.chartsofAccountsTable[state.chartsofAccountsTable.length - 1];
      commit(
        "set_auto_selected_accounts",
        state.chartsofAccountsTable[state.chartsofAccountsTable.length - 1]
      );
    },
  },
  modules: {},
};
