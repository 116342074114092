import store from "../users/store";

const moduleRoute = {
  path: "/sales",
  name: "Sales",
  component: () =>
    import(
      /* webpackChunkName: "sales-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "sales-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },
    {
      path: "/sales/:id",
      component: () =>
        import(
          /* webpackChunkName: "sales-view", webpackPrefetch: true */ "./views/InvoiceView.vue"
        ),
    },
    {
      path: "/sales/invoice/add",
      name: "addNewInvoice",
      component: () =>
        import(
          /* webpackChunkName: "sales-view", webpackPrefetch: true */ "./views/invoice/Add/index.vue"
        ),
    },
    {
      path: "/sales/invoice/edit/:id",
      name: "InvoiceEdit",
      component: () =>
        import(
          /* webpackChunkName: "sales-view", webpackPrefetch: true */ "./views/invoice/Edit/index.vue"
        ),
    },
  ],
  beforeEnter: (to, from, next) => {
    // Check for token first
    if (!store.state.token) {
      localStorage.setItem("layout", "landing-layout");
      return next("/login");
    }
    
const role = localStorage.getItem("role");
localStorage.setItem("layout", "dashboard-layout");

if(role.includes("admin") || role.includes("PM")){
  localStorage.setItem("sales-allowed", true)
  next();
}
else {
  localStorage.setItem("sales-allowed", false)
  next("/"); 
}     
    

    
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
